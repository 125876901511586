import React, {useState} from "react";
import axios from "axios";
import {getTargetAPIForResetPassword, getLangTranslation} from "../functions.ts";
import logo from "../Assets/logo.png";
import logoWithName from "../Assets/logo_with_name.svg";
import successPNG from "../Assets/success.png";
import failedPNG from "../Assets/failed.png";

const ResetPassword = () => {
    // get the oobCode and apiKey from the url
    const queryParameters = new URLSearchParams(window.location.search)
    const oobCode = queryParameters.get("oobCode")
    const apiKey = queryParameters.get("apiKey")
    const lang = queryParameters.get("lang")

    // get the translation based on the lang
    const translation = getLangTranslation(lang, "resetPassword")
    
    // set the state params to default (false) for controlling the UI
    const [isReset, setIsReset] = useState(false);
    const [isResetSuccess, setIsResetSuccess] = useState(false);

    // validate the password
    function validatePassword(e) {
        // get the password and confirm password from the form
        const newPassword = document.getElementById("newPassword").value;
        const confirmNewPassword = document.getElementById("confirmNewPassword").value;
        e.preventDefault();

        // if the password is empty, return
        if (newPassword === "" || confirmNewPassword === "") {
            alert(translation.passwordEmpty);
            return;
        }

        // if the password is less than 8 chars, return
        // if the password doesn't contain english or number chars, return
        if (newPassword.length < 8 || !/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/.test(newPassword)) {
            alert(translation.passwordInvalid);
            return;
        }

        // if the password and confirm password are not the same, return
        if (newPassword !== confirmNewPassword) {
            alert(translation.passwordNotMatch);
            return;
        }

        resetPassword(newPassword);
    }

    // reset the password by calling the Firebase API
    function resetPassword(newPassword) {
        // if oobCode, apiKey or lang is null, return
        if (oobCode == null || apiKey == null || lang == null) {
            window.location.replace("https://combokid.com");
            return;
        }
        
        // hide the form and show the result
        document.getElementById("resetPasswordForm").style.display = "none";
        document.getElementById("resetPasswordResult").style.display = "flex";

        // get the target API based on the first char of publicID
        const targetAPI = getTargetAPIForResetPassword(apiKey);

        // set the params for the API call
        const params =  {
            newPassword: newPassword,
            oobCode: oobCode,
        }
        
        // set the config for the API call
        const config = {
            headers: {
              'content-type': 'application/json',
            }
        }

        // call the target firebase API to verify the email
        // and set the state params for controlling the UI based on the api response
        // or catch the error if the API call is failed
        setTimeout(() => {
            axios.post(
                targetAPI,
                params,
                config,
            ).then((response) => {
                setIsReset(true);
                if (response.status === 200) {
                    setIsResetSuccess(true);
                } else {
                    setIsResetSuccess(false);
                    console.log(response.data);
                }
            }).catch((error) => {
                setIsReset(true);
                setIsResetSuccess(false);
                console.log(error);
            });
        }, 1000);
    }

    // render the UI based on the state params
    return (
        <div className="body">
            <div id="header" className="position-top-left">
                <img src={logoWithName} alt='header logo' className="header-image"/>
            </div>
            <form id="resetPasswordForm" className="position-top-left" onSubmit={validatePassword}>
                <h1>{translation.resetPasswordTitle}</h1>
                <div class="inputDiv">
                    <label className="inputLabel" htmlFor="password">{translation.newPassword}</label>
                    <input 
                        type="password" 
                        id="newPassword" 
                        className="inputPassword" 
                        placeholder={translation.newPasswordPlaceholder} 
                        autocomplete="off"
                        required
                    />
                </div>
                <br />
                <div class="inputDiv">
                    <label className="inputLabel" htmlFor="confirmPassword">{translation.confirmNewPassword}</label>
                    <input 
                        type="password" 
                        id="confirmNewPassword" 
                        className="inputPassword" 
                        placeholder={translation.confirmNewPasswordPlaceholder} 
                        autocomplete="off"
                        required
                    />
                </div>
                <input type="submit" id="submitButton" value={translation.submit} />
            </form>
            <div id="resetPasswordResult" className="result position-top-left">
                {isReset ? (
                    isResetSuccess ? ( 
                        <div>
                            <img src={successPNG} alt="success" className="image-asset"/>
                            <p id="resultSuccess">{translation.resetPwSuccess}</p>
                            <p className="resultMessage">{translation.resetPwSuccessMsg}</p>
                        </div>
                    ) : (
                        <div>
                            <img src={failedPNG} alt="failed" className="image-asset"/>
                            <p id="resultFailed">{translation.resetPwFailed}</p>
                            <p className="resultMessage">{translation.resetPwFailedMsg}</p>
                        </div>
                    )
                ) : (
                    <div>
                        <img src={logo} alt="loading.." id="loading" className="image-asset"/>
                        <p>{translation.resetting}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ResetPassword;