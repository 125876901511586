import {sha256} from "hash.js";
import translation from "./translation.json";

export const generateSignature = (publicID: string): string => {
    const checkSum = generateChecksum(publicID, 0);
    return sha256().update(checkSum).digest("hex");
};

const generateChecksum = (publicID: string, rec = 0): string => {
    // console.log(publicID);
    if (rec > 3) {
        return publicID;
    }
    const charList = publicID.split("");
    const charListLength = charList.length;
    let sum = 0;
    const evenPositionChars: string[] = [];
    const oddPositionChars: string[] = [];
    for (let i = 0; i < charListLength; i++) {
        sum += charList[i].charCodeAt(0);
        if (i % 2 === 0) {
            evenPositionChars.push(charList[i]);
        } else {
            oddPositionChars.push(charList[i]);
        }
    }
    if (sum / 2 === 0) {
        return evenPositionChars.join("")+generateChecksum(oddPositionChars.join(""), rec + 1);
    } else {
        return oddPositionChars.join("")+generateChecksum(evenPositionChars.join(""), rec + 1);
    }
};


export const getTargetAPIForEmailVerify = (target: string) => {
    if (target === "1") {
        return "https://us-central1-dev---combokid-app.cloudfunctions.net/verifyEmailVerification";
    } else if (target === "2") {
        return "https://us-central1-uat---combokid-app.cloudfunctions.net/verifyEmailVerification";
    } else if (target === "3") {
        return "https://us-central1-combokid-app.cloudfunctions.net/verifyEmailVerification";
    }
    return null;
}

export const getTargetAPIForResetPassword= (apiKey: string) => {
    return "https://www.googleapis.com/identitytoolkit/v3/relyingparty/resetPassword?key="+apiKey;
}

export const getLangTranslation = (lang: string, mode: string) => {
    if (lang === "en") {
        return translation.en[mode];
    }
    return translation.zh[mode];
}

    