import './App.css';
import Home from "./Components/Home"
import EmailVerify from "./Components/EmailVerify"
import ResetPassword from "./Components/ResetPassword"
import { Route, Routes, BrowserRouter} from 'react-router-dom';

function App() {
  const queryParameters = new URLSearchParams(window.location.search)
  const mode = queryParameters.get("mode")
  return (
    <BrowserRouter>
      <Routes>
        {mode === "emailVerify"?
          <Route path="/" element={<EmailVerify />} />
          : null
        }
        {mode === "resetPassword"?
          <Route path="/" element={<ResetPassword />} />
          : null
        }
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
