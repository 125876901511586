import React, {useState, useEffect} from "react";
import axios from "axios";
import {generateSignature, getTargetAPIForEmailVerify, getLangTranslation} from "../functions.ts";
import logo from "../Assets/logo.png";
import logoWithName from "../Assets/logo_with_name.svg";
import successPNG from "../Assets/success.png";
import failedPNG from "../Assets/failed.png";

const EmailVerify = () => {
    // get the public_id and lang from the url
    const queryParameters = new URLSearchParams(window.location.search)
    const publicID = queryParameters.get("id")
    const lang = queryParameters.get("lang")

    // get the translation based on the lang
    const translation = getLangTranslation(lang, "emailVerify")
    
    // set the state params to default (false) for controlling the UI
    const [isVerified, setIsVerified] = useState(false);
    const [isVerifiedSuccess, setIsVerifiedSuccess] = useState(false);

    // call the function to verify the email when the page is loaded
    useEffect(() => {
        if (!isVerified) {
            setTimeout(() => {emailVerify()}, 1000);
        }
     }, []);

    // verify the email by calling the Firebase API
    function emailVerify() {
        // if publicID or lang is null, return
        if (publicID == null || lang == null) {
            window.location.replace("https://combokid.com");
            return;
        }

        // get the target API based on the first char of publicID
        const targetAPI = getTargetAPIForEmailVerify(publicID[0])
        if (targetAPI == null) {
            setIsVerified(true);
            setIsVerifiedSuccess(false);
            return;
        }

        // set the params for the API call
        const params =  {
            publicID: publicID,
            signature: generateSignature(publicID),
        }

        // set the config for the API call
        const config = {
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
            }
        }

        // call the target firebase API to verify the email
        // and set the state params for controlling the UI based on the api response
        // or catch the error if the API call is failed
        axios.post(
            targetAPI,
            params,
            config,
        ).then((response) => {
            setIsVerified(true);
            if (response.data.status === "success") {
                setIsVerifiedSuccess(true);
            } else {
                setIsVerifiedSuccess(false);
                console.log(response.data);
            }
        }).catch((error) => {
            setIsVerified(true);
            setIsVerifiedSuccess(false);
            console.log(error);
        });
    }

    // render the UI based on the state params
    return (
        <div className="body">
            <div id="header" className="position-top-left">
                <img src={logoWithName} alt='header logo' className="header-image"/>
            </div>
            <div id="emailVerifyResult" className="result position-top-left">
                {isVerified ? (
                    isVerifiedSuccess ? (
                        <div>
                            <img src={successPNG} alt="success" className="image-asset"/>
                            <p id="resultSuccess">{translation.verifiedSuccess}</p>
                            <p className="resultMessage">{translation.verifiedSuccessMsg}</p>
                        </div>
                    ) : (
                        <div>
                            <img src={failedPNG} alt="failed" className="image-asset"/>
                            <p id="resultFailed">{translation.verifiedFailed}</p>
                            <p className="resultMessage">{translation.verifiedFailedMsg}</p>
                        </div>
                    )
                ) : (
                    <div>
                        <img src={logo} alt="loading..." id="loading" className="image-asset"/>
                        <p>{translation.loading}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EmailVerify;